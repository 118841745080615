// LanguageDropdown.tsx
'use client';

// LanguageDropdown.tsx

import React, { useState } from 'react';

export interface Language {
  id: number;
  name: string;
}

export interface LanguageDropdownProps {
  languages: Language[];
}

export const LanguageDropdown: React.FC<LanguageDropdownProps> = ({ languages }) => {
  const [selectedLanguage, setSelectedLanguage] = useState<Language | null>(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleLanguageSelect = (language: Language) => {
    setSelectedLanguage(language);
    setIsOpen(false);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <span className="dropdown">
      <button
        className="btn btn-light dropdown-toggle"
        type="button"
        id="languageDropdownButton"
        onClick={toggleDropdown}
      >
        {selectedLanguage ? selectedLanguage.name : 'Select Language'}
      </button>
      <ul className={`dropdown-menu${isOpen ? ' show' : ''}`} aria-labelledby="languageDropdownButton">
        {languages.map((language) => (
          <li key={language.id} onClick={() => handleLanguageSelect(language)}>
            <button className="dropdown-item" type="button">
              {language.name}
            </button>
          </li>
        ))}
      </ul>
    </span>
  );
};

// import { IconProp } from '@fortawesome/fontawesome-svg-core';
// import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import React, { useState } from 'react';

// interface Language {
//   id: number;
//   name: string;
// }

// interface LanguageDropdownProps {
//   languages: Language[];
// }

// const LanguageDropdown: React.FC<LanguageDropdownProps> = ({ languages }) => {
//   const [selectedLanguage, setSelectedLanguage] = useState<Language | null>(null);
//   const [isOpen, setIsOpen] = useState(false);

//   const handleLanguageSelect = (language: Language) => {
//     setSelectedLanguage(language);
//     setIsOpen(false);
//   };

//   const toggleDropdown = () => {
//     setIsOpen(!isOpen);
//   };

//   return (
//     <span className="language-dropdown">
//       <span className="selected-language" onClick={toggleDropdown}>
//         {selectedLanguage ? selectedLanguage.name : 'Select Language'}
//         <FontAwesomeIcon icon={faChevronDown as  IconProp} />
//       </span>
//       {isOpen && (
//         <ul className="language-list">
//           {languages.map((language) => (
//             <li key={language.id} onClick={() => handleLanguageSelect(language)}>
//               {language.name}
//             </li>
//           ))}
//         </ul>
//       )}
//     </span>
//   );
// };

// export default LanguageDropdown;
