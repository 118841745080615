'use client';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faFacebook, faInstagram, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styles from './social-media-icon.module.scss';

// Define the props interface
interface SocialMediaIconProps {
  type: 'youtube' | 'facebook' | 'instagram' | 'twitter' | 'website';
  url: string;
}

// Map social media types to FontAwesome icons
const iconMap = {
  youtube: faYoutube,
  facebook: faFacebook,
  instagram: faInstagram,
  twitter: faTwitter, // X icon
  website: faGlobe
};

// Handle click event
const handleClick = (url: string) => {
  window.open(url, '_blank');
};

export const SocialMediaIcon: React.FC<SocialMediaIconProps> = ({ type, url }) => {
  const icon = iconMap[type];

  return (
    <div className={styles.socialMediaIcon} 
    onClick={() => handleClick(url)}
    >
      {icon ? (
        <FontAwesomeIcon icon={icon as IconDefinition} size="2x" />
      ) : (
        <span>Invalid type</span>
      )}
    </div>
  );
};