/* eslint-disable @typescript-eslint/no-explicit-any */
// import process from 'process';

export const utils_ENV = () => {
  return process.env;
};


export const utils_ENV_API = () => {
//   const evnAPINow = process.env.ENV_API
//     ? process.env.ENV_API
//     : ENV_STATE.UNKNOWN;
//   return evnAPINow;
    return 'not implemented';
};


export const utils_ENV_STATE = () => {
//   const evnStateNow = process.env.ENV_STATE
//     ? process.env.ENV_STATE
//     : ENV_STATE.UNKNOWN;
//   return evnStateNow;
  return 'not implemented';
};

export const utils_ENV_PUBLIC_ASSETS_URL = () => {
//   const path = process.env.ENV_PUBLIC_ASSETS_URL
//     ? process.env.ENV_PUBLIC_ASSETS_URL
//     : 'UNKNOWN-ASSETS-URL';
//   return path;
  return 'not implemented';
};
