import { ENV_STATE } from '../base-props';


export const CRMK_APP_PARTS = {
  CONSUMER: 'Consumer',
  ADMIN: 
    { 
      title: 'Admin',
      FILES: {
        title: 'Files',
      },
      MASJID: {
        title: 'Masjid',
      },
      PRAYERS: {
        title: 'Prayers',
      },
      USERS: {
        title: 'Users',
      },
    },


  GENERAL: 'General',
  COMPONENTS_OS: 'Components OpenSource',
  UNDECIDED: 'Undecided',
  SAMPLES: 'Storybook Samples',
  _: '_',
} as const;

export const CRMK_STORIES_DEFAULT_PARAMS = {
  env: {
    // TODO: fix for storybook
    // ENV_STATE: utils_ENV_STATE(),
    // ENV_PUBLIC_ASSETS_URL: utils_ENV_PUBLIC_ASSETS_URL(),

    ENV_STATE: 'not implemented in storybook',
    ENV_PUBLIC_ASSETS_URL: 'not implemented in storybook',
  },
  data: {},
  dataLookup: {
    info: 'check storybook, pulling from loaders, ensure .env ENV_PUBLIC_ASSETS_URL must be set',
  },
};

export const CRMK_STORIES_DEFAULT_PARAMS_ARGS_TYPES = {
  lookupData: {
    control: {
      type: 'object',
    },
  },
  data: {
    control: {
      type: 'object',
    },
  },
  env: {
    control: {
      type: 'object',
      ENV_STATE: Object.values(ENV_STATE).map((v) => `${v}`),
      ENV_PUBLIC_ASSETS_URL: '',
    },
  },
};

export const CRMK_CUSTOM_VIEW_PORTS_DETAILS = {
  desktopMinimum: {
    name: 'Desktop (Minimum)',
    styles: {
      width: '1920px',
      height: '1080px',
    },
  },
  laptopBudget: {
    name: 'Laptop (Budget)',
    styles: {
      width: '1366px',
      height: '768px',
    },
  },
  mobileStandard: {
    name: 'Mobile (Standard)',
    styles: {
      width: '360px',
      height: '480px',
    },
  },
  mobileMinimum: {
    name: 'Mobile (Minimum)',
    styles: {
      width: '320px',
      height: '568px',
    },
  },
};

export enum CRMK_VIEW_PORTS {
  DESKTOP_MINIMUM = 'desktopMinimum',
  LAPTOP_BUDGET = 'laptopBudget',
  MOBILE_MINIMUM = 'mobileMinimum',
  MOBILE_STANDARD = 'mobileStandard',
}
