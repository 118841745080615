'use client';
// Import the jwtDecode function
import { ENV_STATE } from '@crmk-mm/crmk-mm-utils';
import { jwtDecode } from 'jwt-decode';
import { useEffect, useState } from 'react';
import style from './token-detail.module.scss';

export interface StandardClaims {
  sub: string; // Subject - Identifier for the user
  email?: string;
  name?: string;
}

export interface CustomClaims {
  id?: string;
  uid?: string;
  // Add placeholders for other custom attributes you expect
  [key: string]: any; // This allows for any number of custom attributes
}

// A combined type that includes both standard and custom claims
export type DecodedToken = StandardClaims & CustomClaims;

// /**
//  * Decodes the JWT token to extract both standard and custom user information.
//  *
//  * @param token - The JWT token as a string.
//  * @returns An object containing the decoded information, including standard and custom claims.
//  */
// export function decodeToken(token: string): DecodedToken {
//   try {
//     // Use jwtDecode to decode the token
//     const decoded: DecodedToken = jwtDecode(token);
//     return decoded;
//   } catch (error) {
//     console.error('Failed to decode token:', error);
//     throw new Error('Invalid token');
//   }
// }

// create a tsx function takes token as prop
// displays information from the provided token  in a div
// if token is invalid it will display an error message "Invalid Token" in red color
export function convertTokenExpiryToDate(exp: number): string {
  const date = new Date(exp * 1000); // Convert seconds to milliseconds
  return date.toUTCString(); // Converts to a readable UTC string. You can format this as needed.
}

export const TokenDetail = (props: {
  rk: number; // random key to re-render the component
  token: string;
  className: string;
  onValidityCheck: (response: {
    isValid: boolean;
    isGuest: boolean;
    tokenInfo: any;
  }) => void;
}) => {
  const isDebug = process.env.ENV_STATE === ENV_STATE.DEBUG ;
  

  const { rk, token, className, onValidityCheck } = props;
  const [value, setValue] = useState<number>(rk); // to re-render the component

  const [isDecoding, setIsDecoding] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [tokenInfo, setTokenInfo] = useState<DecodedToken | null>(null);
  const [isValid, setIsValid] = useState<boolean | null>(null);
  const [isAppGuest, setIsAppGuest] = useState<boolean>(true);

  const isAppGuestEmail = (email: string) => {
    const isGuest = /^app-guest-(.+)@myiqama.com$/.test(email);
    alert(`${email} - isGuest: ${isGuest}`);
    return isGuest;
  };

  const handleDecodeToken = async () => {
    setIsDecoding(true);
    setError(null);

    try {
      // Simulate async operation if needed
      const decoded: DecodedToken = jwtDecode(token);
      alert(JSON.stringify(decoded));
      setTokenInfo(decoded);

      setIsAppGuest(isAppGuestEmail(decoded.email ?? 'email not found'));
      const currentTime = Date.now() / 1000; // Convert to seconds

      if (decoded.exp > currentTime) {
        setIsValid(true);
      } else {
        setIsValid(false);
      }
    } catch (e) {
      setError('Failed to decode token.');
      setTokenInfo(null);
    } finally {
      setIsDecoding(false);
      setIsValid(false);
      onValidityCheck({
        isValid: isValid ?? false,
        isGuest: isAppGuest,
        tokenInfo,
      });
    }
  };

  useEffect(() => {
    try {
      // async method
      const am = async () => {
        await handleDecodeToken();
      };

      am().catch((err) => {
        console.error(err);
      });
    } catch (error) {
      console.error(error);
    }
  });

  return (
    <div className={className}>
      {
        !isDebug && (
          <div>
            <pre>
              <code>{JSON.stringify( {
                rk,
                token
              } , null, 2)}</code>
            </pre>
          </div>
        )
      }
      {isValid && (
        <>
          <div className={style.validToken}>
            <span className={style.icon}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="currentColor"
                className="bi bi-check-circle-fill"
                viewBox="0 0 16 16"
              >
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
              </svg>
            </span>

            <span className={style.iconLabel}>Token is valid</span>
          </div>
        </>
      )}

      {!isValid && (
        <>
          <div className={style.invalidToken}>
            <span className={style.icon}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="red"
                viewBox="0 0 16 16"
              >
                <circle cx="8" cy="8" r="8" fill="#FF0000" />
                <path
                  fill="white"
                  d="M7.002 11a1 1 0 1 0 2 0 1 1 0 0 0-2 0zM7.1 4.995a.905.905 0 0 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z"
                />
              </svg>
            </span>
            <span className={style.iconLabel}>Invalid Token</span>
          </div>
        </>
      )}
      {!isDebug && (
        <div>
          <pre>
            <code>{JSON.stringify(tokenInfo, null, 2)}</code>
          </pre>
          <pre className={style.wrapper}>
            <code>{JSON.stringify(token, null, 2)}</code>
          </pre>
        </div>
      )}
    </div>
  );
};

// // Example usage
// const token = 'YOUR_JWT_TOKEN_HERE';
// const userInfo = decodeToken(token);
// console.log('User Info:', userInfo);
