// implemented https://github.com/rikhoffbauer/react-abac/tree/main
// instead using https://github.com/Permify/react-role


import { Permission } from './permissions';
import { Role } from './roles';

export const Rules = {
  [Role.SUPER_ADMIN]: {
    [Permission.DAILY_PRAYERS_SCHEDULE_EDIT]: true,
    [Permission.JUMA_PRAYERS_SCHEDULE_EDIT]: true,
    [Permission.MASJID_INFO_EDIT]: true,
    [Permission.MASJID_FILES]: true,
    [Permission.PRAYER_SETTINGS_EDIT]: true,
    [Permission.MASJID_CONTRIBUTORS_EDIT]: true,
  },
  [Role.ADMIN]: {
    [Permission.DAILY_PRAYERS_SCHEDULE_EDIT]: true,
    [Permission.JUMA_PRAYERS_SCHEDULE_EDIT]: true,
    [Permission.MASJID_FILES]: true,
    [Permission.MASJID_INFO_EDIT]: false,
    [Permission.PRAYER_SETTINGS_EDIT]: false,
    [Permission.MASJID_CONTRIBUTORS_EDIT]: false,
  },
};


export type User = {
  id: string,
  name: string;
  rules: typeof Rules;
  permissions: Permission[];
  role: Role[];
};
