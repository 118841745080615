'use client';
import { useEffect, useState } from 'react';

interface TimeSinceComponentProps {
  utcDate: string; // UTC date string in ISO 8601 format
  message?: string;
}

export function TimeSinceComponent({ utcDate, message }: TimeSinceComponentProps) {
  const [timeSince, setTimeSince] = useState<string>(''); // Initial state

  const calculateTimeSince = () => {
    const now = new Date();
      const then = new Date(utcDate);
      const diff = now.getTime() - then.getTime(); // Calculate time difference in milliseconds

      const minute = 60 * 1000;
      const hour = 60 * minute;
      const day = 24 * hour;
      const week = 7 * day;
      const month = 30 * day; // Approximation
      const year = 365 * day; // Approximation

      if (diff < minute) {
        setTimeSince('just now');
      } else if (diff < hour) {
        setTimeSince(`${Math.round(diff / minute)} minutes ago`);
      } else if (diff < day) {
        setTimeSince(`${Math.round(diff / hour)} hours ago`);
      } else if (diff < week) {
        setTimeSince(`${Math.round(diff / day)} days ago`);
      } else if (diff < month) {
        setTimeSince(`${Math.round(diff / week)} weeks ago`);
      } else if (diff < year) {
        const months = Math.round(diff / month);
        const remainingDays = Math.round((diff % month) / day);
        setTimeSince(
          months === 1
            ? `${months} month and ${remainingDays} days ago`
            : `${months} months and ${remainingDays} days ago`
        );
      } else {
        const years = Math.round(diff / year);
        setTimeSince(years === 1 ? `${years} year ago` : `${years} years ago`);
      }
    }

  useEffect(() => {
    calculateTimeSince();
  });

  useEffect(() => {
    const interval = setInterval(() => {
      calculateTimeSince();
    }, 60000); // Update every minute

    return () => clearInterval(interval); 
  }, [utcDate]); // Dependency array: update when utcDate changes

  return <span>{message && `${message} `}{timeSince}</span>;
}
