/* eslint-disable @typescript-eslint/no-explicit-any */

export type typeMessage =
  | 'success'
  | 'error'
  | 'warning'
  | 'info'
  | 'none'
  | '';


function subscribe(
  eventName: string,
  listener: EventListenerOrEventListenerObject
) {
  document.addEventListener(eventName, listener);
}

function unsubscribe(
  eventName: string,
  listener: EventListenerOrEventListenerObject
) {
  document.removeEventListener(eventName, listener);
}

enum CUSTOM_EVENT_DB_NAMES {
  CREATE_RECORD = 'CREATE_RECORD',
  UPDATE_RECORD = 'UPDATE_RECORD',
  DELETE_RECORD = 'DELETE_RECORD',
  FETCH_RECORD = 'FETCH_RECORD',
  QUERY_RECORDS = 'QUERY_RECORDS',
  PARTIAL_UPDATE_RECORD = 'PARTIAL_UPDATE_RECORD',
}

type customEventDBOperationsReturn = {
  status: boolean;
  message: string;
  type: typeMessage;
  response?: any;
};

/**
 * @description you could use 'libs/crmk-mm-ui/src/lib/components/notifications/notifications-snack-bars.tsx' message as return type
 */
type customEventDBOperations = (
  eventDB: CUSTOM_EVENT_DB_NAMES,
  eventData: any
) => Promise<customEventDBOperationsReturn>;

/**
 * @description this triggers snack bar message if parent component implemented NotificationsSnackBar
 * @param type
 * @param message
 * @param showMs - show message for milliseconds
 */
type notificationMessage = (
  type: typeMessage,
  message: string,
  showMs?: number
) => any;

/**
 * @deprecated use customEventDBOperations instead
 * TO-DO: Custom event as prop
 * @param eventName
 * @param data
 */
function customEventDB(
  eventName: CUSTOM_EVENT_DB_NAMES,
  // eventName: customEventDBNames
  data: any
) {
  const event = new CustomEvent(eventName, { detail: data });
  document.dispatchEvent(event);
}

export {
  CUSTOM_EVENT_DB_NAMES,
  customEventDB,
  customEventDBOperations,
  customEventDBOperationsReturn,
  notificationMessage,
  subscribe,
  unsubscribe
};

// // create custom events
// const catFound = new CustomEvent('animalfound', {
//   detail: {
//     name: 'cat',
//   },
// });
// const dogFound = new CustomEvent('animalfound', {
//   detail: {
//     name: 'dog',
//   },
// });

// // add an appropriate event listener
// obj.addEventListener('animalfound', (e) => console.info(e.detail.name));

// // dispatch the events
// obj.dispatchEvent(catFound);
// obj.dispatchEvent(dogFound);
