'use client';
import React, { useState } from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';
import { BsMoon, BsSun } from 'react-icons/bs'; // Assuming these are icons for light and dark themes

interface ThemeToggleButtonProps {
  initialTheme?: 'light' | 'dark';
}

export const ThemeToggleButton: React.FC<ThemeToggleButtonProps> = ({ initialTheme = 'light' }) => {
  const [theme, setTheme] = useState<'light' | 'dark'>(initialTheme);
  const [selectedButton, setSelectedButton] = useState<'light' | 'dark'>(initialTheme);

  const toggleTheme = (selectedTheme: 'light' | 'dark') => {
    setTheme(selectedTheme);
    setSelectedButton(selectedTheme);
  };

  return (
    <span className={`theme-toggle-button ${theme === 'light' ? 'light-theme' : 'dark-theme'}`}>
      <ButtonGroup>
        <Button
          onClick={() => toggleTheme('dark')}
          variant={selectedButton === 'dark' ? 'dark' : 'outline-secondary'}
        >
          {selectedButton === 'dark' ? <BsMoon size={12} color="white" /> : <BsSun size={12} color="black" />}
          <span style={{ fontSize: '12px', color: selectedButton === 'dark' ? 'white' : 'black' }}>Dark Theme</span>
        </Button>
        <Button
          onClick={() => toggleTheme('light')}
          variant={selectedButton === 'light' ? 'outline-dark' : 'outline-secondary'}
        >
          {selectedButton === 'light' ? <BsSun size={12} color="black" /> : <BsMoon size={12} color="white" />}
          <span style={{ fontSize: '12px', color: selectedButton === 'light' ? 'black' : 'white' }}>Light Theme</span>
        </Button>
      </ButtonGroup>
    </span>
  );
};
