
export interface IID {
  id: string;
}

export interface IAdmin {
  admin_createdBy: string;
  admin_updatedBy: string;
}

// interface to track the mode for ui form
export enum UIFormMode {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
  READ = 'READ',
}
export interface IUIForm {
  mode: 'CREATE' | 'UPDATE' | 'DELETE' | 'READ';
}


