'use client';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faApple, faGoogle } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './app-download-modal.module.scss'; // Import SCSS styles

// Define the interface for props (if needed)
export interface AppModalProps {
  isVisible: boolean;
  onClose: () => void;
  androidAppUrl: string;
  iosAppUrl: string;
}

export const AppModal = (props: AppModalProps) => {
  const { isVisible, onClose, androidAppUrl, iosAppUrl } = props; // Destructure props
  if (!isVisible) return null; // Only render when the modal is visible

  return (
    <div className={styles.modal}>
      <div className={styles.modalContent}>
        <h5>Now available as Mobile application for your Phone</h5>
        <div className={styles.links}>
          <a href={androidAppUrl} target="_blank" className={styles.appLinks}>
            <FontAwesomeIcon
              icon={faGoogle as IconProp}
              className={styles.appIcon}
            />
            Download Android app (on PlayStore)
          </a>

          <a href={iosAppUrl} target="_blank" className={styles.appLinks}>
            <FontAwesomeIcon
              icon={faApple as IconProp}
              className={styles.appIcon}
            />
            Download IPhone app (on AppStore)
          </a>
        </div>

        <button onClick={onClose}>close</button>
      </div>
    </div>
  );
};
