export async function utils_CopyTextToClipboard(text: string) {
  try {
    if ('clipboard' in navigator) {
      await navigator.clipboard.writeText(text);
      return true;
    } else {
      document.execCommand('copy', true, text);
      return true;
    }
  } catch (err) {
    console.error('Failed to copy: ', err);
    return false;
  }
}
