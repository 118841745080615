'use client';
import { AppModal } from '@/components/client';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
// import 'server-only';

interface DownloadButtonProps {
  route: string;
}

// <Link href={route} passHref>
// </Link>

export const DownloadButton: React.FC<DownloadButtonProps> = ({ route }) => {
  const [showModal, setShowModal] = useState<boolean>(false);

  return (
    <>
      <Button
        variant="outline-dark"
        className="icon-button"
        onClick={() => {
          setShowModal(!showModal);
        }}
      >
        <FontAwesomeIcon icon={faDownload as IconProp} />
      </Button>

      <AppModal
        androidAppUrl="https://play.google.com/store/apps/details?id=com.myiqama"
        iosAppUrl="https://apps.apple.com/ca/app/myiqama/id6477884107"
        isVisible={showModal}
        onClose={() => {
          setShowModal(false);
        }}
      ></AppModal>
    </>
  );
};
