'use client';
import { utils_isValidImageUrl, utils_MonthName } from '@crmk-mm/crmk-mm-utils';
import { Suspense, useEffect, useState } from 'react';
import styles from './full-screen-img.module.scss';

export interface FullScreenImageComponentProps {
  imageUrl: string;
  imgAlt?: string;
}

export const FullScreenImageComponent = (
  props: FullScreenImageComponentProps
) => {
  const { imageUrl, imgAlt } = props;
  const [isValidImageUrl, setIsValidImageUrl] = useState(false);

  useEffect(() => {
    try {
      const am = async () => {
        const isValid = await utils_isValidImageUrl(imageUrl);
        if (!isValid) console.error(`Invalid image url: ${imageUrl}`);

        setIsValidImageUrl(isValid);
      };

      am().catch((err) => {
        console.error(err);
      });
    } catch (error) {
      console.error(error);
    }
  }, [imageUrl]);

  const [isModalOpen, setIsModalOpen] = useState(false);

  // Handle click event to toggle modal
  const handleImageClick = () => {
    isValidImageUrl && setIsModalOpen(true);
  };

  // Handle closing the modal
  const handleCloseModal = () => {
    isValidImageUrl && setIsModalOpen(false);
  };

  return (
    <div className={styles['image-container']}>
      {/* Suspense for lazy loading the image */}
      <Suspense fallback={<div>....</div>}>
        {/* TODO: change to Image component of next/image */}
        {isValidImageUrl ? (
          <img
            src={imageUrl}
            alt={imgAlt || 'image'}
            className={styles['thumbnail-image']}
            onClick={handleImageClick}
          />
        ) : (
          <div className={styles['message']} onClick={handleImageClick}>
            {` Prayer schedule for ${utils_MonthName(
              new Date().getMonth()
            )} ${new Date().getFullYear()} not available yet`}
            <br />
            please contribute by emailing to{' '}
            <a href="mailto:contribute@myiqama.com">contribute@myiqama.com</a>.
          </div>
        )}
      </Suspense>

      {/* Modal for displaying full-screen image */}
      {isModalOpen && (
        <div className={styles['modal']}>
          <div className={styles['modal-overlay']} onClick={handleCloseModal}>
            <div className={styles['modal-content']}>
              <button
                className={styles['close-button']}
                onClick={handleCloseModal}
              >
                &times;
              </button>
              <img
                src={imageUrl}
                alt={imgAlt || 'image'}
                className={styles['full-screen-image']}
                onClick={(e) => e.stopPropagation()} // Prevent closing modal when clicking the image
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
