/* eslint-disable @typescript-eslint/no-explicit-any */

export enum ENV_STATE {
  DEBUG = 'DEBUG',
  DEBUG_DETAILED = 'DEBUG_DETAILED',
  PROD = 'PROD',
  UNKNOWN = 'UNKNOWN',
  STAGE = 'STAGE',
}


export enum ENV_API {
  STAGE = 'STAGE',
  PROD = 'PROD',
}

export interface IProcessEnv {
  ENV_STATE: ENV_STATE;
  ENV_PUBLIC_ASSETS_URL: string;
}

// GUIDE: update below if any new env variable added/modified/removed.
export interface DefaultProps {
  lookupData?: any;
  data?: any;
  env?: IProcessEnv | any;
}

export interface LocaleTimeZone {
  locale: string;
  timeZone: string;
  timeZoneOffSetMinutes: number;
}