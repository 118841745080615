/**
 * @description works with env.ENV_PUBLIC_ASSETS_URL url
 */
export const PUBLIC_ASSETS = {
  LOOKUP: {
    FIXED: {
      COUNTRIES: '/lookup/fixed/_countries_states_cities.json',
      CALENDAR_MONTHS_DAYS: '/lookup/fixed/_lookup_data_month_days.json',
      PRAYER_NAMES: '/lookup/fixed/_lookup_data_prayers.json',
    },
    DYNAMIC: {
      SUNRISE_SUNSET:
        '/lookup/dynamic/sunrise_sunset/_lookup_data_sunrise_nrcc_2023_langley.json',
    },
  },
  IMAGES: {
    FAVICON: '/images/icons/favicon.png',
  }
};
