import { DEFAULT_LOCALE, DEFAULT_TIMEZONE } from '../const';

/**
 * 
 * @param monthNumber zero index based month number
 * @returns 
 */
export const utils_MonthNumber = (monthNumber?: number) => {
  const date = new Date();
  const month = monthNumber && monthNumber<12 ? monthNumber + 1 : date.getMonth() + 1; 

  return month < 10 ? `0${month}` : `${month}`;
}

/**
 * 
 * @param monthNumber zero index based month number
 * @returns 
 */
export const utils_MonthName = (monthNumber?: number) => {
  const date = new Date();
  const month = monthNumber && monthNumber < 12 ? monthNumber : date.getMonth();

  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  return monthNames[month];
};

export const utils_FormattedDate = (
  date: string | Date | number | null,
  locale = DEFAULT_LOCALE,
  timeZone = DEFAULT_TIMEZONE
) => {
  return date
    ? new Date(date).toLocaleString(locale, {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        weekday: 'long',
        timeZone: timeZone,
      })
    : 'invalid date';
};

export const utils_FormattedTime = (
  date: string | Date | number | null,
  locale = DEFAULT_LOCALE,
  timeZone = DEFAULT_TIMEZONE
) => {
  return date
    ? new Date(date).toLocaleString(locale, {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
        timeZone: timeZone,
      })
    : 'invalid date';
};

export const utils_FormattedDateTimeZone = (
  date: string | Date | number | null,
  locale = DEFAULT_LOCALE,
  timeZone = DEFAULT_TIMEZONE
) => {
  return date
    ? new Date(date).toLocaleString(locale, {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        weekday: 'long',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
        timeZone: timeZone,
      })
    : 'invalid date';
};

export const utils_FormattedDateOnlyTimeZone = (
  date: string | Date | number | null,
  locale = DEFAULT_LOCALE,
  timeZone = DEFAULT_TIMEZONE
) => {
  return date
    ? new Date(date).toLocaleString(locale, {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        weekday: 'long',
        timeZone: timeZone,
      })
    : 'invalid date';
};

/**
 * @description next Friday date from the given date. please note it returns the date as per timezone in given date
 */
export const utils_NearestFridayDate = (
  dated: Date | string,
  offsetMinutes = 0,
  locale = DEFAULT_LOCALE,
  timeZone = DEFAULT_TIMEZONE
) => {
  const fridayDate = new Date(dated);
  fridayDate.setUTCMinutes(fridayDate.getUTCMinutes() + offsetMinutes);

  let day = fridayDate.toLocaleDateString(locale, {
    weekday: 'long',
    timeZone: timeZone,
  });
  while (day !== 'Friday') {
    fridayDate.setDate(fridayDate.getDate() + 1);
    day = fridayDate.toLocaleDateString(locale, {
      weekday: 'long',
      timeZone: timeZone,
    });
  }
  return fridayDate;
};

export const utils_WeekDayName = (
  year: number,
  month: number,
  dayOfMonth: number,
  locale = 'en-CA'
) => {
  const day = new Date(`${year}/${month}/${dayOfMonth}`);
  return day.toLocaleDateString(locale, {
    weekday: 'long',
  });
};

export function utils_AddMinutesToDateTime(
  dateToCompare: Date,
  minutes: number
) {
  const newTime = new Date(dateToCompare.getTime());
  newTime.setMinutes(newTime.getMinutes() + minutes);
  return newTime;
}

/**
 * @description updates date part of the given DateTimeObject using UTC.
 * @returns
 */
export function utils_UpdateDatePartOnlyToDateTime(
  datePartFomDate: Date,
  dateToDateTime: Date
) {
  const newDateTiem = new Date(dateToDateTime.getTime());
  newDateTiem.setFullYear(datePartFomDate.getFullYear());
  newDateTiem.setMonth(datePartFomDate.getMonth());
  newDateTiem.setDate(datePartFomDate.getDay());
  return newDateTiem;
}

/**
 * @description updates date part of the given DateTimeObject using UTC.
 * @returns
 */
export function utils_UpdateTimePartOnlyToDateTime(
  datePartFomDate: Date,
  dateToDateTime: Date
) {
  const newDateTiem = new Date(dateToDateTime.getTime());
  newDateTiem.setHours(datePartFomDate.getHours());
  newDateTiem.setMinutes(datePartFomDate.getMinutes());
  newDateTiem.setSeconds(datePartFomDate.getSeconds());
  return newDateTiem;
}

export function utils_GetUTCDateOnly(date: Date | string | number) {
  let dt: Date = new Date();
  if (typeof date === ('string' || 'number')) {
    dt = new Date(date);
  } else {
    dt = date as Date;
  }
  return new Date(
    Date.UTC(dt.getUTCFullYear(), dt.getUTCMonth(), dt.getUTCDate(), 0, 0, 0, 0)
  );
}

/**
 * @description return date part only after adding offset minutes to the given date.
 */
export function utils_GetUTCDateFromOffSet(
  date: Date | string | number,
  offsetMinutes: number
) {
  let dt: Date = new Date();
  if (typeof date === 'string' && typeof date === 'number') {
    dt = new Date(date);
  } else {
    dt = date as Date;
  }
  const mins = dt.getUTCMinutes() + offsetMinutes;
  dt.setUTCMinutes(mins);
  dt.setUTCHours(0, 0, 0, 0);
  return dt;
}

export const updateTimePartOfDateTime = (
  datePartFrom: Date | null | string,
  dateToTime: Date | null | string
): Date | string | null => {
  let from;
  let to;
  let updatedDate = null;
  try {
    if (datePartFrom) {
      if (
        typeof datePartFrom === 'string' ||
        typeof dateToTime === typeof new Date()
      ) {
        from = new Date(datePartFrom);
      }
    }

    if (dateToTime) {
      if (
        typeof dateToTime === 'string' ||
        typeof dateToTime === typeof new Date()
      ) {
        to = new Date(dateToTime);
        updatedDate = to;
      }
    }

    if (from && to) {
      updatedDate = utils_UpdateTimePartOnlyToDateTime(from, to);
    }
  } catch (error) {
    console.error(error);
    return dateToTime;
  }

  return updatedDate;
};

export const utils_FormattedDateYYYYMMDDFromDigitsString = (
  eightDigitString: string
) => {
  if (eightDigitString.length !== 8 || !/^\d+$/.test(eightDigitString)) {
    return 'invalid date'; // Invalid input
  }

  const year = eightDigitString.slice(0, 4);
  const month = eightDigitString.slice(4, 6);
  const day = eightDigitString.slice(6, 8);

  return `${year}-${month}-${day}`;
};
/**
 *
 * @param dateString date part
 * @returns ISO Date Part  + Current Time concatenated
 */
export const utils_GetISODateOnlyFromYYYYMMDD = (dateString: string) => {
  let isoDate: string;
  const invalidDate = 'Invalid date format';
  if (dateString) {
    if (dateString === 'today') {
      const date = new Date();
      isoDate = date.toISOString();
      return isoDate;
    }
    if (dateString === 'tomorrow') {
      const date = new Date();
      date.setDate(date.getDate() + 1);
      isoDate = date.toISOString();
      return isoDate;
    }

    const datePart = dateString.replace(/-/g, '');

    if (datePart.length === 8) {
      const date = new Date(
        utils_FormattedDateYYYYMMDDFromDigitsString(datePart)
      );
      if (isNaN(date.getTime())) {
        throw new Error(invalidDate);
      }
      isoDate =
        date.toISOString().split('T')[0] +
        'T' +
        new Date().toISOString().split('T')[1];
      return isoDate;
    } else {
      throw new Error(invalidDate);
    }
  } else {
    throw new Error(invalidDate);
  }
};
