'use client';
import { utils_CopyTextToClipboard } from '@crmk-mm/crmk-mm-utils';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faShareNodes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';

export interface CopyToClipboardComponentProps {
  content: string;
  message?: string;
  successMessage?: string;
}

export const CopyToClipboardComponent = (
  props: CopyToClipboardComponentProps
) => {
  const { content, message, successMessage } = props;
  const handleIconButtonCopyClick = () => {
    const url = content;
    utils_CopyTextToClipboard(url);
    setShowMessageDiv(true);
  };

  const [showMessageDiv, setShowMessageDiv] = useState(false);
  useEffect(() => {
    if (showMessageDiv) {
      // If div was just shown
      setTimeout(() => {
        setShowMessageDiv(false); // Hide after 5 seconds
      }, 3000);
    }
},[showMessageDiv])


  return (
    <>
    <div className={'btn btn-outline-secondary btn-sm ms-3'}>
      <FontAwesomeIcon
        icon={faShareNodes as IconProp}
        onClick={handleIconButtonCopyClick}
        // TODO: tooltip is not showing.
        title={message ? message : 'Copied to clipboard'}
      />
    </div>
    {showMessageDiv && <div className={'text-success small ms-3'}>{successMessage}</div>}
    </>
  );
};
