import { ENV_STATE } from '../base-props';
import { PUBLIC_ASSETS } from '../const-assets';
import { utils_ENV_STATE } from './utils-node';

// const hostPublicFolder = utils_ENV_PUBLIC_ASSETS_URL();

export function utils_dataFromJsonFile(
  filePath: string,
  publicFolder: string 
) {
  if (utils_ENV_STATE() === ENV_STATE.DEBUG) {
    console.info('fetch-url: ', `${publicFolder}${filePath}`);
  }

  if (publicFolder === '') {
    return Promise.reject([]);
  }

  const url = `${publicFolder}${filePath}`;
  // alert(`url: ${url}`)

  const options =
    PUBLIC_ASSETS.LOOKUP.FIXED.COUNTRIES === filePath
      ? {
          // cache: 'no-store',
          // method: 'GET',
          // compress: true,
        }
      : undefined;
  const result = fetch(
    url,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    options as any
  )
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      return data;
    })
    .catch((e: Error) => {
      console.error(e.message);
      return [];
    });
  return result;
}
