'use client'

import { ConsumerAppInfoProps } from './app-download';
import styles from './download.module.scss';

  
export const Download = (props: ConsumerAppInfoProps) => {

  // Handle Android download click
  const handleAndroidDownload = () => {
    // OWASP: Ensure download links are safe
    window.location.href = props.downloadLinks.android;
  };

  // Handle iOS download click
  const handleiOSDownload = () => {
    // OWASP: Ensure download links are safe
    window.location.href = props.downloadLinks.ios;
  };

  return (
    <>
      <div className={styles.container}>
        <h1>{props.name}</h1>
        <h2> {props.title}</h2>
        <p>{props.description}</p>
        
        <h3>Available Platforms</h3>
        
        <ul>
          {props.platforms.map((platform, index) => (
            <li key={index}>{platform}</li>
          ))}
        </ul>
        <h3>Download Links</h3>
        <button
          className={styles['download-button']}
          onClick={handleAndroidDownload}
        >
          Download for Android
        </button>
        <button
          className={styles['download-button']}
          onClick={handleiOSDownload}
        >
          Download for iOS
        </button>
        <p className={styles['search-term']}>OR {props.description2[1]}</p>
      </div>
    </>
  );
}



