'use client';
import { ENV_STATE } from '@crmk-mm/crmk-mm-utils';
// components/FetchDisplay.tsx
import React, { useEffect, useState } from 'react';

export interface FetchDisplayProps {
  url: string; // URL to fetch data from
}

export const FetchDisplay: React.FC<FetchDisplayProps> = ({ url }) => {
  const isDebug = process.env.ENV_STATE === ENV_STATE.DEBUG;
  const [content, setContent] = useState<string>('');
  const [error, setError] = useState<string>('');

  useEffect(() => {
    isDebug && console.log('Fetching content from:', url);
    // GDPR: Ensure you're compliant with data processing and privacy regulations when fetching data
    fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Failed to fetch');
        }
        return response.text();
      })
      .then(setContent)
      .catch((error) => {
        console.error('Error fetching content:', error);
        setError('Failed to load content');
      });
  }, [url]); // Depend on the URL, refetch if it changes

  if (error) {
    return <div>Error: {error}</div>;
  }

  //   return <div>{content || 'Loading...'}</div>;
  return (
    <div
      dangerouslySetInnerHTML={{ __html: content ? content : 'Loading...' }}
    />
  );
};


